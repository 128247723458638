.page-about {
  width: 60%;
  max-width: 1440px;
  margin: 0 auto;

  p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 16px;
  }
}

@media only screen and (min-width: 375px) {
  .page-about {
    padding: 0 16px;
    width: 100%;

    h1 {
      margin: 24px 0 24px;

      font-size: 24px;
    }

    p {
      font-size: 13px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .page-about {
    padding: 0 24px;

    h1 {
      margin: 24px 0 40px;

      font-size: 32px;
    }

    p {
      font-size: 14px;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .page-about {
    padding: 0 24px;
    width: 80%;

    p {
      font-size: 16px;
    }
  }
}

@media only screen and (min-width: 1280px) {
  .page-about {
    width: 60%;

    p {
      font-size: 16px;
    }
  }
}
