.culture-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 64px;

  @media only screen and (min-width: 375px) {
    flex-direction: column;
    gap: 24px;
  }

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    gap: 40px;
  }

  @media only screen and (min-width: 1024px) {
    gap: 64px;
  }
}

.culture-item-image-container {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 330px;
  height: 270px;

  border-radius: 16px;

  overflow: hidden;

  .culture-item-image {
    height: 100%;
  }
}

.culture-item-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .culture-item-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 16px;
    width: 64px;
    height: 64px;

    background-color: var(--color-blue-super-light);
    border-radius: 50%;

    .culture-item-icon {
      display: flex;
      justify-content: center;
      align-items: center;

      color: var(--color-blue-super-dark);
    }
  }

  .culture-item-heading {
    margin-bottom: 16px;

    color: var(--text-color-super-dark);

    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5;
  }

  .culture-item-description {
    color: var(--text-color-super-dark);

    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;
  }

  @media only screen and (min-width: 375px) {
    .culture-item-icon-container {
      display: none;
    }

    .culture-item-heading {
      align-self: center;
      margin-bottom: 16px;
      font-size: 16px;
    }

    .culture-item-description {
      font-size: 14px;
    }
  }

  @media only screen and (min-width: 768px) {
    .culture-item-icon-container {
      display: flex;

      width: 40px;
      height: 40px;

      .culture-item-icon {
        font-size: 24px;
      }
    }

    .culture-item-heading {
      align-self: flex-start;
      margin-bottom: 16px;
      font-size: 18px;
    }

    .culture-item-description {
      font-size: 16px;
    }
  }

  @media only screen and (min-width: 1024px) {
    .culture-item-icon-container {
      width: 64px;
      height: 64px;

      .culture-item-icon {
        font-size: 36px;
      }
    }

    .culture-item-heading {
      margin-bottom: 16px;
      font-size: 20px;
    }

    .culture-item-description {
      font-size: 18px;
    }
  }
}
