.product-item {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 40px;

  background-color: #fff;
  border-radius: 4px;

  cursor: pointer;

  &:hover {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    transform: translateY(-16px);
    transition: all 0.3s;
  }

  &-image {
    margin-bottom: 24px;
    width: 240px;
    height: 240px;
  }

  &-name {
    margin-bottom: 32px;

    color: var(--text-color-super-dark, #121435);

    font-size: 24px;
    font-weight: 500;
    line-height: normal;
  }
}

@media only screen and (min-width: 375px) {
  .product-item {
    width: 100%;
    max-width: 160px;
    padding: 8px;

    &-image {
      margin-bottom: 8px;
      width: 100%;
      height: 100%;
    }

    &-name {
      margin-bottom: 16px;
      font-size: 16px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .product-item {
    width: 100%;
    max-width: 192px;
    padding: 16px;

    &-image {
      margin-bottom: 24px;
      width: 100%;
      height: 100%;
    }

    &-name {
      margin-bottom: 32px;
      font-size: 24px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .product-item {
    width: 100%;
    max-width: 240px;
    padding: 16px;

    &-image {
      margin-bottom: 24px;
      width: 100%;
      height: 100%;
    }

    &-name {
      margin-bottom: 32px;
      font-size: 24px;
    }
  }
}
