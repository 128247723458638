.culture-list {
  display: flex;
  flex-direction: column;
  gap: 96px;
  align-items: center;

  margin: 0 auto;
  width: 80%;

  list-style: none;
}

@media only screen and (min-width: 375px) {
  .culture-list {
    width: 100%;

    gap: 40px;
  }
}

@media only screen and (min-width: 768px) {
  li:nth-child(even) .culture-item {
    flex-direction: row-reverse;
  }
}
