.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  z-index: 100;

  &.dark {
    border-bottom: 1px solid var(--color-neutral-black);

    .header-content {
      .nav-btn {
        .nav-btn-icon {
          color: var(--color-blue-super-dark);
        }
      }
    }
  }

  .header-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin: 0 auto;
    max-width: 1440px;
    width: 100%;

    .logo-container {
      display: flex;
      flex-direction: row;
      gap: 16px;
      align-items: center;
    }

    .nav-btn {
      width: 44px;
      height: 44px;

      display: flex;
      justify-content: center;
      align-items: center;

      border: none;
      background-color: transparent;

      cursor: pointer;

      // FIXME:
      &-icon {
        color: #fff;
      }
    }

    .main-nav-list {
      display: flex;
      flex-direction: row;
      gap: 40px;

      list-style: none;
    }

    .main-nav-link {
      font-weight: 500;
      text-decoration: none;

      &.dark {
        color: var(--text-color-super-dark);
      }

      &.light {
        color: var(--text-color-white);
      }
    }
  }
}

@media only screen and (min-width: 375px) {
  .header {
    padding: 0 16px;
    height: 64px;

    .header-content {
      .logo-container {
        .logo-image {
          height: 32px;
        }

        .logo-text {
          display: none;
          height: 32px;
        }
      }

      .nav-btn {
        display: block;
      }

      .main-nav {
        display: none;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .header {
    padding: 0 24px;
    height: 96px;

    .header-content {
      .logo-container {
        .logo-image {
          height: 40px;
        }

        .logo-text {
          display: none;
          height: 32px;
        }
      }

      .nav-btn {
        display: none;
      }

      .main-nav {
        display: block;
      }
    }
  }
}
