:root {
  --color-blue-super-dark: #1864ab;
  --color-blue-dark: #1971c2;
  --color-blue-primary: #1c7ed6;
  --color-blue-light: #339af0;
  --color-blue-super-light: #a5d8ff;

  --color-neutral-black: #000;
  --color-neutral-gray-333: #333;
  --color-neutral-gray-666: #666;
  --color-neutral-gray-999: #999;
  --color-neutral-gray-c4: #c4c4c4;
  --color-neutral-gray-f2: #f2f2f2;
  --color-neutral-white: #fff;

  --text-color-super-dark: #121435;
  --text-color-dark: #44566c;
  --text-color-medium: #8996a6;
  --text-color-light: #cdcfd2;
  --text-color-white: #fff;
}

.container {
  margin: 0 auto;
  max-width: 1440px;
}

.section {
  @media only screen and (min-width: 375px) {
    margin-bottom: 96px;
  }

  @media only screen and (min-width: 768px) {
    margin-bottom: 128px;
  }
}

.heading {
  font-family: 'Merriweather', serif;
}

.heading-primary {
  font-weight: 700;
  line-height: 1.3;
  letter-spacing: -1px;
}

.heading-secondary {
  margin-bottom: 96px;
}

.heading-tertiary {
  margin-bottom: 24px;

  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 240px;

  border-radius: 4px;
  border: 1px solid transparent;

  line-height: 1;

  cursor: pointer;

  @media only screen and (min-width: 375px) {
    height: 40px;
    font-size: 16px;
  }

  @media only screen and (min-width: 768px) {
    height: 56px;
    font-size: 20px;
  }
}

.btn-fullwidth {
  width: 100%;
}

.btn:disabled {
  background: var(--color-neutral-gray-999);
  color: var(--color-neutral-white);
  cursor: not-allowed;
}

.btn-primary:not(:disabled) {
  background: var(--color-blue-primary);
  color: var(--text-color-white);

  @media only screen and (min-width: 375px) {
    box-shadow: 0px 2px 2px #1971c2;
  }

  @media only screen and (min-width: 768px) {
    box-shadow: 0px 2px 4px #1971c2;
  }

  @media only screen and (min-width: 1024px) {
    box-shadow: 0px 2px 8px #1971c2;
  }
}

.text-align-center {
  text-align: center;
}
