.page-terms {
  margin: 0 auto;
}

@media only screen and (min-width: 375px) {
  .page-terms {
    width: 100%;
    padding: 0 16px;

    h1 {
      margin: 24px 0 24px;

      font-size: 24px;
    }

    h2 {
      font-size: 18px;
      margin-bottom: 14px;
    }

    h3 {
      font-size: 16px;
    }

    p {
      margin-bottom: 12px;
      font-size: 13px;
    }

    ol {
      margin-bottom: 8px;

      font-size: 13px;

      li {
        margin: 0 0 8px 14px;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .page-terms {
    padding: 0 24px;

    h1 {
      margin: 24px 0 40px;

      font-size: 32px;
    }

    h2 {
      font-size: 24px;
    }

    h3 {
      font-size: 20px;
      margin-bottom: 16px;
    }

    p {
      font-size: 14px;
      margin-bottom: 14px;
    }

    ol {
      margin-bottom: 14px;

      font-size: 14px;

      li {
        margin: 0 0 12px 16px;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .page-terms {
    width: 80%;

    p {
      font-size: 16px;
      margin-bottom: 16px;
    }

    ol {
      margin-bottom: 16px;

      font-size: 16px;

      li {
        margin: 0 0 16px 18px;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .page-terms {
    width: 60%;
  }
}
