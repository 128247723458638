#hero {
  position: relative;
  top: -96px;

  width: 100%;

  background-image: url('../../../public/images/hero-bg-blur.png');

  .hero-container {
    padding-top: 240px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 48px;
  }

  .hero-image-container {
    width: 36%;
    min-width: 360px;

    .hero-image {
      width: 100%;
    }
  }

  .hero-text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 56px;

    width: 64%;

    color: var(--text-color-white);

    .hero-heading {
      font-size: 40px;
      text-transform: uppercase;
    }

    .hero-slogan {
      font-size: 24px;
      font-weight: 500;
      line-height: 150%;
      text-transform: capitalize;
    }

    .hero-warning {
      font-size: 16px;
      font-weight: 500;
      line-height: 150%;
    }

    .hero-cta {
      padding: 12px 48px;
      width: auto;
    }
  }

  @media only screen and (min-width: 375px) {
    margin-bottom: 0;

    .hero-container {
      flex-direction: column;

      padding: 128px 16px 60px;

      .hero-image-container {
        display: none;
      }

      .hero-text-container {
        align-items: center;
        gap: 0;

        width: 100%;
        padding-left: 16px;
        padding-right: 16px;

        .hero-heading {
          margin-bottom: 56px;
          width: 100%;

          text-align: center;
          font-size: 32px;

          font-weight: 700;
          line-height: 1.2;
          letter-spacing: -0.96px;
          text-transform: capitalize;
        }

        .hero-slogan {
          margin-bottom: 80px;

          text-align: center;
          font-size: 18px;
          line-height: 150%; /* 27px */
          text-transform: capitalize;
        }

        .hero-warning {
          order: 1;
          text-align: center;
          font-size: 12px;
        }

        .hero-cta {
          width: 100%;
          max-width: 480px;
          margin-bottom: 24px;
        }
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    .hero-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 48px;

      padding: 128px 24px 60px;

      .hero-image-container {
        display: block;
        width: 36%;
      }

      .hero-text-container {
        width: 64%;
        align-items: flex-start;
        gap: 56px;
        padding: 0;

        .hero-heading {
          margin-bottom: 0;
          font-size: 40px;
          text-align: left;
          text-transform: capitalize;
          letter-spacing: -1px;
        }
        .hero-slogan {
          margin-bottom: 0;
          font-size: 24px;
          font-weight: 500;
          line-height: 150%;
          text-align: left;
          text-transform: capitalize;
        }

        .hero-warning {
          order: 0;
          margin-bottom: 0;

          font-size: 16px;
          font-weight: 500;
          line-height: 150%;
          text-align: left;
        }

        .hero-cta {
          padding: 12px 48px;
          width: auto;
        }
      }
    }
  }
}

#products {
  @media only screen and (min-width: 375px) {
    padding-left: 16px;
    padding-right: 16px;

    .products-heading {
      margin-bottom: 48px;

      text-align: center;
      font-size: 24px;
      line-height: 1.2;
    }
  }
}

#why {
  @media only screen and (min-width: 375px) {
    padding-left: 16px;
    padding-right: 16px;

    .why-heading {
      margin-bottom: 48px;

      text-align: center;
      font-size: 24px;
      line-height: 1;
    }
  }
}

#about {
  margin-bottom: 160px;

  .about-text-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 24px;

    margin: 0 auto;
    width: 80%;

    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;

    .about-text {
      width: 50%;
    }
  }
  @media only screen and (min-width: 375px) {
    padding-left: 16px;
    padding-right: 16px;

    margin-bottom: 96px;

    .about-heading {
      margin-bottom: 48px;

      text-align: center;
      font-size: 24px;
      line-height: 1;
    }

    .about-text-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 24px;

      margin: 0 auto;
      width: 100%;

      font-size: 18px;
      font-weight: 400;
      line-height: 1.5;

      .about-text {
        width: 100%;

        color: var(--Text-Color-Super-Dark, #121435);
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 160px;

    .about-text-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 24px;

      margin: 0 auto;
      width: 80%;

      font-size: 18px;
      font-weight: 400;
      line-height: 1.5;

      .about-text {
        width: 60%;
      }
    }
  }
}
