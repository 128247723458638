.footer {
  width: 100%;
  padding-top: 40px;
  padding-bottom: 64px;

  background-color: var(--color-blue-super-dark);
}

.footer-content {
  display: flex;
  justify-content: space-between;

  margin: 0 auto;
  max-width: 1440px;

  color: var(--color-neutral-white);

  a {
    color: var(--color-neutral-white);
  }

  .footer-text-container {
    display: flex;

    .footer-contacts {
      .contacts-address {
        display: flex;
        flex-direction: column;

        font-style: normal;
      }
    }

    .footer-resources {
      .footer-nav {
        display: flex;
        flex-direction: column;

        list-style: none;
      }
    }

    .footer-heading {
      color: var(--text-color-white);

      font-weight: 500;
      line-height: 1.5;
    }

    .footer-link:hover {
      text-decoration: underline;

      cursor: pointer;
    }
  }
}

@media only screen and (min-width: 375px) {
  .footer {
    padding-left: 16px;
    padding-right: 16px;

    .footer-content {
      flex-direction: column;
      gap: 24px;

      .footer-logo-container {
        .footer-logo {
          height: 96px;
        }
      }

      .footer-text-container {
        flex-direction: column;
        gap: 24px;

        .footer-contacts {
          .contacts-address {
            gap: 8px;
            font-size: 12px;
          }
        }

        .footer-resources {
          .footer-nav {
            gap: 8px;
            font-size: 12px;
          }
        }

        .footer-heading {
          margin-bottom: 16px;
          font-size: 13px;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .footer {
    padding-left: 24px;
    padding-right: 24px;

    .footer-content {
      gap: 32px;

      .footer-logo-container {
        .footer-logo {
          height: 104px;
        }
      }

      .footer-text-container {
        flex-direction: column;
        gap: 32px;

        .footer-contacts {
          .contacts-address {
            gap: 12px;
            font-size: 13px;
          }
        }

        .footer-resources {
          .footer-nav {
            gap: 12px;
            font-size: 13px;
          }
        }

        .footer-heading {
          margin-bottom: 20px;
          font-size: 16px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .footer {
    .footer-content {
      flex-direction: row;
      gap: 24px;

      .footer-logo-container {
        .footer-logo {
          height: 128px;
        }
      }

      .footer-text-container {
        flex-direction: row;
        gap: 32px;

        .footer-contacts {
          .contacts-address {
            gap: 12px;
            font-size: 14px;
          }
        }

        .footer-resources {
          .footer-nav {
            gap: 12px;
            font-size: 14px;
          }
        }

        .footer-heading {
          margin-bottom: 20px;
          font-size: 16px;
        }
      }
    }
  }
}
