.page-welcome {
  width: 100vw;
  height: 100vh;

  background: url('../../../public/images/welcome-bg.jpg');
  background-size: cover;
  background-position: center;
}

.welcome-card {
  display: flex;
  flex-direction: column;
  gap: 56px;

  margin: 0 auto;

  width: 100%;
  max-width: 560px;

  background-color: var(--color-neutral-white);
  border-radius: 4px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);

  .card-heading {
    text-align: center;
  }

  .user-information {
    display: flex;
    flex-direction: column;

    .input-label {
      display: block;

      color: var(--text-color-super-dark, #121435);
      font-weight: 500;
      line-height: 1.5;
    }
  }

  .confirmation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .text-warning {
      color: var(--Text-Color-Dark, #44566c);
      font-weight: 500;
      line-height: 1.5;
    }

    .btn-confirm {
      width: 100%;
    }

    .text-terms {
      align-self: flex-end;

      color: var(--text-color-dark, #44566c);

      font-weight: 500;
      line-height: 1.5;

      .link-terms {
        text-decoration: underline;
      }
    }
  }
}

@media only screen and (min-width: 375px) {
  .page-welcome {
    padding: 40px 16px 0;

    .welcome-card {
      padding: 40px 16px;
      gap: 40px;

      .card-heading {
        font-size: 24px;
      }

      .user-information {
        gap: 16px;

        .input-label {
          margin-bottom: 8px;
          font-size: 14px;
        }
      }

      .confirmation {
        gap: 20px;

        .text-warning {
          font-size: 13px;
        }

        .text-terms {
          font-size: 12px;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .page-welcome {
    padding: 96px 24px 0;

    .welcome-card {
      padding: 64px 40px 80px;

      .card-heading {
        font-size: 32px;
      }

      .user-information {
        gap: 32px;

        .input-label {
          margin-bottom: 16px;
          font-size: 16px;
        }
      }

      .confirmation {
        gap: 24px;

        .text-warning,
        .text-terms {
          font-size: 14px;
        }
      }
    }
  }
}
