.product-details-container {
  margin: 0 auto;
}

.btn-back {
  display: inline-block;

  color: var(--text-color-super-dark, #121435);

  font-weight: 500;

  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.product-details {
  display: flex;

  .product-image-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .product-image {
      width: 100%;
    }
  }

  .product-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 50%;

    .product-title {
      color: var(--text-color-super-dark);

      font-weight: 600;
      line-height: 1.2;
      letter-spacing: -1px;
      text-transform: capitalize;
    }

    .product-specs {
      display: grid;
      grid-template-columns: auto auto;

      &-heading {
        color: var(--text-color-dark, #8996a6);

        font-weight: 600;
        line-height: 1.5;
      }

      &-content {
        color: var(--text-color-super-dark, #121435);

        font-weight: 500;
        line-height: 1.5;
      }
    }

    .product-description {
      color: var(--text-color-super-dark, #121435);

      font-weight: 400;
      line-height: 1.5;
    }
  }
}

.referral {
  .referral-heading {
    display: block;

    color: var(--text-color-super-dark, #121435);

    font-weight: 600;
    line-height: 1.5;
  }
}

@media only screen and (min-width: 375px) {
  .product-details-container {
    padding: 24px 16px 40px;
    width: 100%;
  }

  .btn-back {
    margin-bottom: 24px;

    font-size: 14px;
    font-weight: 500;
  }

  .product-details {
    flex-direction: column;
    align-items: center;
    gap: 32px;

    margin-bottom: 64px;

    .product-image-container {
      width: 100%;

      .product-image {
        width: auto;
        height: 240px;
      }
    }

    .product-info {
      width: 100%;

      .product-title {
        margin-top: 0;
        margin-bottom: 24px;

        font-size: 24px;
      }

      .product-specs {
        row-gap: 16px;
        column-gap: 12px;

        margin-bottom: 24px;

        &-heading {
          font-size: 14px;
        }

        &-content {
          font-size: 14px;
        }
      }

      .product-description {
        font-size: 14px;
      }
    }
  }

  .referral {
    .referral-heading {
      font-size: 20px;
      margin-bottom: 14px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .product-details-container {
    padding: 24px 16px 40px;
  }

  .btn-back {
    margin-bottom: 24px;

    font-size: 14px;
    font-weight: 500;
  }

  .product-details {
    flex-direction: row;
    align-items: flex-start;
    gap: 32px;

    margin-bottom: 64px;

    .product-image-container {
      width: 100%;
      max-width: 400px;

      .product-image {
        width: 100%;
        height: auto;
      }
    }

    .product-info {
      padding-top: 5%;
      width: 100%;

      .product-title {
        margin-top: 24px;
        margin-bottom: 24px;

        font-size: 32px;
      }

      .product-specs {
        row-gap: 16px;
        column-gap: 16px;

        margin-bottom: 24px;

        &-heading {
          font-size: 14px;
        }

        &-content {
          font-size: 14px;
        }
      }

      .product-description {
        font-size: 14px;
      }
    }
  }

  .referral {
    .referral-heading {
      font-size: 24px;
      margin-bottom: 16px;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .product-details-container {
    padding: 64px 24px 96px;
  }

  .btn-back {
    margin-bottom: 84px;
    font-size: 20px;
  }

  .product-details {
    flex-direction: row;
    justify-content: space-between;
    gap: 64px;

    margin-bottom: 160px;

    .product-image-container {
      width: 100%;
      max-width: 480px;
    }

    .product-info {
      width: 100%;

      .product-title {
        margin-top: 40px;
        margin-bottom: 32px;

        font-size: 48px;
      }

      .product-specs {
        row-gap: 24px;
        column-gap: 24px;

        margin-bottom: 56px;

        &-heading {
          font-size: 16px;
        }

        &-content {
          font-size: 16px;
        }
      }

      .product-description {
        font-size: 16px;
      }
    }
  }

  .referral {
    .referral-heading {
      font-size: 24px;
      margin-bottom: 40px;
    }
  }
}
